import React from 'react'

class Home extends React.Component {
    render() {
        return (
            <div className="home">
                <p>[Home Component]</p>
            </div>
        )
    }
}

export default Home
