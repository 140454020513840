import React from 'react'

function ComingSoon (props) {
    return (
        <div className="coming-soon">
            <h1>Coming Soon!</h1>
        </div>
    )
}

export default ComingSoon
