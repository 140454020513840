import React from 'react'

class Shop extends React.Component {
    constructor() {
        super();
        this.state = { }
    }

    render() {
        return (
            <div className="shop">[Shop Component]</div>
        )
    }
}

export default Shop
