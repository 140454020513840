import React from 'react'

class Cookbook extends React.Component {
    render() {
        return (
            <div className="cookbook">
                <p>[Cookbook Component]</p>
            </div>
        )
    }
}

export default Cookbook
